@import './vars';


@media screen and (max-width: 1332px) {
    .white_header_menu::after, .white_header_menu a:hover::after{
        width: 100%;
    }
    .inform_search{
        width: 100%;
    }
    .white_share{
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        row-gap: 40px;
        flex-direction: column;
    }
    .white_header_menu{
        justify-content: space-between;
        column-gap: 30px;
        row-gap: 25px;       
        width: 100%;
        flex-wrap: wrap;
    }
}


@media screen and (max-width: 1024px) {
    .white_bluePopular_title{
        margin-top: 0;
    }
    .white_bluePopular{
        margin-bottom: 0;
    }
    .white_share{
        column-gap: 40px;
    }
    .white_blueSidebar{
        display: none;
    }
    .white_blueSidebar .active{
        display: flex;
    }
    .white_boxof{
        display: none;
    }
    .white_music, .white_story{
        border: none;
    }
    .white_boxof, .white_music, .white_story{
        padding: 5px;
    }
    .white_blueSocial_vk{
        height: 55px;
    }
    .white_music__title-heading{
        margin-top: 0;
    }
    .white_boxof, .white_music, .white_story{
        padding-top: 0;
        padding-bottom: 0;
    }
    .white_blueSidebar{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100000;
        background-color: var(--c-4);
        padding: 90px 5px 10px;
        border: 4px solid var(--c-5);
        box-sizing: border-box;
    }
    .white_bluePopular__box{
        width: 100%;
    }
    .white_header_logo{
    margin-bottom: 30px;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    }
    .white_blueSite{
        padding-top: 40px;
    }
    .white_blueWrapper{
        width: 100%;
    }
    .white_header_menu::after{
        width: 130%;
        top: 74px;
    }
    .white_header_inner{
        justify-content: center;
        display: flex;
        flex-flow: wrap;
    }
    .white_mobile__button{
        display: flex;
        justify-self: end;
    }
    .white_track-info, .white_track-central{
        height: 70px;
    }
    .white_mute-box__muteUnmute{
        display: none;
    }
    .white_mute-box{
        display: none;
    }

    .white_letter:hover .white_letter__cnt{
        z-index: 2;
        left: 0;
    }
    .white_player-title{
        display: flex;
        width: 100%;
        justify-content: center;
    }
    .play::before, .pause::before{
        width: 75px;
        height: 75px;
    }
    .white_track-central_left-btn svg, .white_track-central_right-btn svg{
        width: 45px;
        height: 45px;
    }
    .white_copyright_box-mail{
        font-size: 25px;
        & a{
            font-size: 25px;
        }
    }
}

@media screen and (max-width: 992px) {
    .white_melodious__list a{
        font-size: 24px;
    }
    .white_track-info{
        margin-right: 60px;
    }
    }
@media screen and (max-width: 920px) {
}

@media screen and (max-width: 768px) {
    .white_player-logo{
        width: 50px;
        height: 50px;
    }
    .white_bluePopular{
        align-items: center;
    }
    .white_card_link::before{
        width: 55px;
        height: 55px;
    }
    .white_card_download svg{
        width: 45px;
        height: 45px;
    }
    .white_card_download{
        right: 10px;
    }
    .white_card-subtext{
        right: 80px;
    }
    .white_card_link::before{
        width: 50px;
        height: 50px;
    }
    .white_card_artist::after{
        font-size: 20px;
    }
    .white_card_artist, .white_card_tracklink{
        font-size: 16px;
        line-height: 20px;
    }
    .white_header_logo{
        margin-bottom: 0;
    }
    .white_bluePopular__box{
        margin-top: 15px;
        justify-content: center;
    }
    .dur{
        font-size: 17px;
    }
    .white_card_time{
        right: -10px;
    }
    .white_card{
        height: 90px;
        padding: 0 0 0 10px;
    }

    .ya-share2__container{
        text-align: center;
    }
    #inform_search__form{
        width: auto;
    }
    .white_share{
        row-gap: 20px;
    }
    .white_share{
        flex-direction: column;
    }
    .pleer{
        width: 100%;
        box-sizing: border-box;
    }
    .white_bluePopular_title{
        font-size: 22px;
        text-align: center;
    }
    .white_description_title{
        font-size: 19px;
    }
    .white_player-title_heading{
        margin-right: 0;
        font-size: 25px;
    }
    .white_track__leftWrapper{
        justify-self: end;
    }
    .white_player-title{
        justify-content: center;
    }
    .white_track-central{
        margin-right: 0;
    }
    .white_results-info__card{
        padding: 15px 10px;
    }
    .white_melodious__list{
        flex-direction: row;
    }
    .white_melodious__list a{
        margin-right: 5px;
        margin-left: 5px;
    }
    .white_boxline__bottom{
        text-align: center;
    }
    .white_mute-box{
        margin-right: 30px;
    }
    .white_about{
        flex-direction: column;
    }
    .white_logo-link{
        display: none;
    }
    .white_wrapper{
        margin-left: 0;
    }

    .white_mobile-nav__button{
        width: min-content;
        justify-self: end;
    }
    .white_song-aplhabet{
        width: auto;
    }
    .white_popular_title{
        font-size: 25px;
    }
    .white_popular_logo{
        width: 40px;
        height: 40px;
    }
    .white_copyright_box-mail{
        font-size: 24px;
    }
    .white_sidebar{
        display: none;
    }
    .white_wrapper{
        width: 100%;
    }
    .white_melodious__list a::before{
        display: none;
    }
    .white_footer_copy{
        width: 100%;
    }
}

@media screen and (max-width: 624px) {
    .white_blueSite{
        padding-top: 20px;
    }
    .white_player-logo{
        display: none;
    }
    .f-1{
        margin-bottom: 0;
    }
    .white_card_download{
        height: 45px;
    }
    .white_copyright_box-mail{
        margin-bottom: 180px;
    }
    .white_header_menu a{
        margin-left: 0;
        margin-right: 0;
    }
    .white_letter__logo{
        fill: var(--c-2);
        stroke: var(--c-2);
    }
    .white_letter__title{
        color: var(--c-2);
    }
    .white_card-subtext{
        bottom: auto;
    }
    .white_card{
        height: 70px;
    }
    .white_card_link{
        margin-right: 10px;
    }
    .white_card_link::before{
        width: 46px;
        height: 45px;
    }
    .white_card_download svg{
        width: 45px;
        height: 45px;
    }
    .white_header_menu{
        display: flex;
        width: 100%;
    }
    .white_blueSidebar{
        width: 100%;
    }
    .white_player-title_heading{
        margin-bottom: 10px;
        margin-top: 5px;
    }
    .white_copyright_box-mail a{
        margin-left: 0;
    }
    .track{
        flex-direction: column;
    }
    .white_track-central{
        border-right: 0;
        padding-right: 0;
        margin-right: 0;
    }
    .social-box{
        right: 30px;
    }

    .white_about{
        margin-bottom: 190px;
    }
    .white_track-central{
        margin-right: 0;
    }
    .white_track-info{
        margin-bottom: 10px;
    }
    .white_mute-box {
        width: auto;
        justify-self: center;
        grid-column-start: 2;
        margin-right: 0;
    }
    .white_zero-time{
        margin-left: 0;
        font-size: 16px;
    }
    .white_time_space{
        font-size: 16px;
    }
    .white_timeBox{
        font-size: 16px;
    }
    .white_copyright_box-mail{
        flex-direction: column;
        row-gap: 10px;
    }
}

@media screen and (max-width: 670px) {
    .pause::before, .play::before{
        width: 60px;
        height: 60px;
    }
    .import_ser-melodious__list a{
        justify-content: center;
    }
}

@media screen and (max-width: 577px) {
    .title{
        line-height: 1.5;
    }
    .white_header_logo-link{
        font-size: 28px;
    }
    .white_mobile__button-line::after, .white_mobile__button-line::before, .white_mobile__button-line{
        width: 40px;
        height: 4px;
    }
    .white_xpopular_logo{
        display: none;
    }
    .white_popular_title{
        text-align: center;
    }
    .white_sotial-network-vk_btn{
        width: 100%;
        margin-bottom: 21px;
        font-size: 20px;
    }
    .white_sotial-network-vk_link{
        width: 100%;
        text-align: center;
    }
    .white_sotial-network-vk_input{
        flex-direction: column;
    }


    .white_download_vk{
        width: 100%;
    }
    .white_vk{
        width: 100%;
        min-width: auto;
    }
    .white_social-box{
        padding-bottom: 0;
    }
    .white_player-title_heading{
        margin-right: 0;
    }
    .white_results-info__card_download{
        width: 40px;
        height: 40px;
    }
}    


@media screen and (max-width: 510px) {
    .white_card_time{
        display: none;
    }
    .white_bluePopular_title{
        width: 100%;
        justify-content: center;
    }
    .white_share{
        margin-bottom: 10px;
    }
    
   
    .white_card_name{
        margin-right: 55px;
    }
    .white_card:hover .white_card-subtext{
        display: none;
    }
    .white_bluePopular{
        margin-top: 35px;
    }
    .white_track-info{
        margin-right: 0;
    }
    .white_player-title{
        margin-top: 0;
    }
    .white_copyright_box-mail{
        font-size: 20px;
        margin-top: 10px;
        text-align: center;
    }
    .white_player-title_heading{
        font-size: 20px;
        text-align: center;
        margin-top: 0;
    }
    .progress-bar__container{
        flex-direction: column;
    }
    .white_blueSocial_vk{
        display: none;
    }
    .res-card_st-bf_results-info__card-subtext, .white_subinfo__time{
        display: none;
    }
    .white_player-title{
        text-align: center;
        margin-left: 0;
        justify-content: center;
        margin-bottom: 0;
    }
    .white_description{
        margin-bottom: 20px;
    }
    .track{
        display: flex;
        flex-direction: column;
    }
    .white_mute-box{
        display: none;
    }
    .white_track-info{
        flex-direction: column;
        align-items: center;
    }

}

@media screen and (max-width: 470px) {
    .title{
        max-height: 60px;
      }
    .white_card-subtext{
        display: none;
    }
    .white_card_artist::after{
        margin-left: 5px;
    }
    .white_card_name{margin-right: 55px;}
    .white_card_artist{
        margin-right: 5px;
    }
    .white_player-title_heading{
        font-size: 18px;
    }
}

@media screen and (max-width: 425px) {
    .white_description_content{
        column-gap: 10px;
    }
    .white_header{
        margin-bottom: 10px;
    }
    .white_story_track-title{
        width: 100%;
    }
    .white_story_track-link, .white_genre-item{
        width: 100%;
    }
    .white_genre-item_link{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .white_header_menu a:hover::after{
        display: flex;
        justify-content: center;
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        padding-top: 40px;
        border-bottom: 3px solid var(--c-2);
        z-index: 2;
    }
    .white_header_menu a::after{
        display: flex;
        justify-content: center;
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        padding-top: 42px;
        border-bottom: 3px solid var(--c-2);
        z-index: 2;
        left: 50%;
        transform: translate(-50%,0);
    }
    .white_header_menu{
        margin-bottom: 0;
    }
    .white_letter{
        display: none;
    }
    .white_header_menu::after{
        display: none;
    }
    .white_header_menu a{
        width: 100%;
    }
    .white_boxof{
        display: none;
    }
    #inform_search__form{
        margin-bottom: 10px;
    }
    .white_bluePopular{
        margin-top: 0;
    }
    .white_card{height: 90px;}
    .submit{
        padding-left: 13px;
        padding-right: 13px;
    }
    .white_blueContainer{
        padding: 0 5px;
    }
    .music-artist__card_artist, .music-artist__card_tracklink{
        max-height: 17px;
    }
    .white_header_menu{
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
    }
    .white_header_menu a{
        font-size: 21px;
        display: flex;
        justify-content: center;
    }
    .music-artist__card_download svg{
        width: 40px;
        height: 25px;
    }
    .music-artist__card_download{
        width: 40px;
        height: 20px;
    }
    .title{
        font-size: 14px;
    }
    .music-artist__card{
        height: 70px;
        padding: 10px 5px 5px 5px;
    }
    .music-artist__card_time{
        display: none;
    }
    .music-artist__card .start{
        transform: scale(0.6);
    }
    .music-artist__card_download{
        position: static;
    }
    .music-artist__card-subtext{
        display: none;
    }
    .res-card_st-bf_results-info__card{
        padding: 15px 10px;
    }
    .white_popular__box{
        justify-content: center;
    }
    .white_melodious__heading{
        text-align: center;
    }
    .white_description_title{
        text-align: center;
    }
    .white_results-info__card_time{
        margin-right: 0;
    }
    .white_results-info__card-subtext{
        margin-right: 10px;
        margin-left: 15px;
    }
    .import_ser-results-info__card_artist, .import_ser-results-info__card_space, .import_ser-results-info__card_tracklink{
        font-size: 14px;
    }
    .import_ser-results-info__card_like{
        display: none;
    }
    
    .import_ser-results-info__card_download svg{
        width: 30px;
        height: 30px;
    }
    .white_results-info__card.pausing a.import_ser-results-info__card_link::before {
        content: url(../images/musicTheme-play.svg);
        padding-left: 12px;
        padding-right: 8px;
        height: 22px;
    }
    .white_results-info__card_link::before{
        width: 20px;
        height: 20px;
        padding: 11px;
    }
    .white_results-info__card .start{
        width: 40px;
        height: 40px;
    }
    .white_vk::before{
        min-width: calc(300px + 6px);
    }
    .white_melodious__list{
        display: flex;
        flex-direction: column;
    }
    .white_melodious__list a{
        font-size: 34px;
    }
    .white_header_logo-link{
        font-size: 34px;
    }
    .inform_input-content{
        padding: 10px;
    }
    .submit{
        padding: 10px;
    }
    .submit svg{
        width: 30px;
        height: 40px;
    }
}

@media screen and (max-width: 375px) {
    .ya-share2__container_mobile.ya-share2__container_size_l .ya-share2__icon{
        width: 40px !important;
        height: 40px !important;
        background-size: 40px !important;
    }
    .white_copyright_box-mail a{
        font-size: 22px;
    }
    .inform_input-content{
        width: 210px;
    }
    .ova-menu-decor-icon svg{
       display: none;
    }
    .white_header_logo-link{
        font-size: 28px;
    }
    .white_header{
        padding: 5px;
    }
    .white_mobile__button-line{
        margin-bottom: 4px;
        margin-top: 4px;
        width: 35px;
        width: 35px;
    }
} 