@import "./vars";


@mixin scrollbars($size, $foreground-c, $foreground-c-hover, $background-color: mix($foreground-c, white,  50%), $radius: 0) {
}


/*Border Component END*/
.ova-menu-decor-icon{
  display: flex;
  align-items: center;
}

.white_mobile__button{
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  position: relative;
  z-index: 999;
  width: 40px;
  height: 30px;
  justify-content: center;
  align-items: center;
  display: none;
  row-gap: 3px;
}

.white_mobile__button-line{
  
  &::before, &::after{
  right: 0;
  position: absolute;
  content: '';
  width: 50px;
  height: 5px;
  background-color: var(--c-2);
  transition: transform 0.3s ease-in, top 0.15s ease-in 0.15s;
  }
}

.white_blueSocial_vk {
    border: 3px solid var(--c-5);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    height: 70px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    display: flex;
    justify-content: center;
  & span{
    color: var(--c-5)
  };
}

.white_blueSocial_vk:hover {
  background: var(--c-5);
  box-shadow: 1px 1px 25px 10px var(--c-5);
  transition: .6s ease-in-out;
  & span{
    color: #fff;
  }
}





// @keyframes glowing {
//   0% { background-position: 0 0; }
//   50% { background-position: 400% 0; }
//   100% { background-position: 0 0; }
// }

.white_music, .white_story, .white_boxof{
  box-sizing: border-box;
  border-radius: 2px;
  padding: 20px;
  border: 3px solid var(--c-5);
  background-color: var(--c-4);
}

.ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item{
  margin-left: 10px !important;
}

.white_header_logo{
  display: flex;
  align-items: center;
}

.ya-share2__link{
  background: var(--c-2) !important;
  border-radius: 6px;
}

.ova-menu-decor-icon svg{
  width: 50px;
  height: 50px;
  margin-right: 9px;
}

.white_share{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  flex-direction: row-reverse;
}

.ya-share2__container.ya-share2__container_c-scheme_blackwhite .ya-share2__badge, .ya-share2__container.ya-share2__container_c-scheme_blackwhite .ya-share2__mobile-popup-badge{
  background-color: var(--c-1) !important;
}

.ya-share2__container_size_l .ya-share2__badge .ya-share2__icon{
  height: 50px!important;
  width: 50px!important;
  background-size: 50px 50px!important;
  border: 1px solid var(--c-2)!important;
  border-radius: 7px!important;
  color: var(--c-2)!important;
}

.ya-share2__container.ya-share2__container_color-scheme_blackwhite .ya-share2__badge, .ya-share2__container.ya-share2__container_color-scheme_blackwhite .ya-share2__mobile-popup-badge{
  background-color: transparent !important;
}

.ya-share2__container_mobile .ya-share2__badge{
  border-radius: 7px !important;
}

.white_men{
  font-size: 34px;
  font-weight: 700;
  color: var(--c-1);
  position: relative;
  &::after{
    content: '';
    position: absolute;
    bottom: -15px;
    width: 110px;
    height: 5px;
    background: var(--c-1);
    display: block;
  }
}

.white_boxof{
  box-sizing: border-box;
  margin-bottom: 40px;
  width: 100%;
}

/* can delete*/

.white_card_name{
  margin-right: 170px;
}

.inform_input-content{
  font-weight: 700;
   -webkit-text-fill-color: var(--c-2);
}


ul{
  padding: 0;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-size: 15px;
  background: var(--c-3);
}

.white_blueSocial{
  width: 200px;
  display: flex;
  justify-content: space-between;
}

.white_blueSite{
  display: flex;
  justify-content: center;
  column-gap: 60px;
}

.inform_input{
  color: #00ffd5;
  width: 100%;
&-content{
  background: 0 0;
  font-size: 22px;
  border: none;
  padding: 12px;
  width: 100%;
  &:hover,&:focus, &:active {
    border: none;
    outline: 0;
    outline-offset: 0;
  }
  &::placeholder{
    font-weight: 700;
    color: var(--c-2);
  }
}
}

.white_letter__cnt{
  margin-top: 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.white_letter{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  &__link{
    font-weight: 400;
    margin-top: 0;
    color: var(--c-2);
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 30px;
    text-align: center;
    padding: 5px;
    &:hover{
      color: #fff;
      border-radius: 7px;
      background: var(--c-5);
    }
  }
  &__heading{
    display: flex;
    width: 100%;
    justify-content: center;
  }
  &__title{
    font-weight: 700;
    font-size: 22px;
    margin: 0;
    color: var(--c-1);
    font-weight: 700;
  }
  &__logo{
    margin-left: 22px;
    fill: var(--c-1);
    width: 43px;
    height: 43px;
    stroke: var(--c-1);
  }
}

.white_mobile__button {
  display: none;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  position: relative;
  z-index: 9999999;
  flex-direction: column;
  &-line{
      margin-bottom: 5px;
      margin-top: 5px;
      display: block;
      position: relative;
      width: 40px;
      height: 4px;
      border-radius: 10px;
      background-color: var(--c-1);
  }
}

.white_blueContainer {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 15px;
}

.white_sotial-network-vk{
  display: flex;
  flex-direction: column-reverse;
}

.white_header{
  margin-bottom: 25px;
  background: var(--c-4);
  padding: 25px 0;
  &_menu{
    display: flex;
    column-gap: 45px;
    position: relative;
  }
  &_menu a{
    color: var(--c-2);
    font-size: 24px;
    position: relative;
    &:hover{
      font-weight: 700;
      color: var(--c-5);
    }
      
    
   
  }
  &_inner{
    display: flex;
    justify-content: space-between;
  }
  &_logo-link{
    font-size: 34px;
    font-weight: 900;
    color: var(--c-2);
  }
}

.white_blueWrapper{
  flex-flow: wrap;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.white_bluePopular{
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__box{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_title{
    font-weight: 700;
    color: var(--c-2);
    font-size: 38px;
    margin-bottom: 10px;
  }
}



.pages{
  outline-color: var(--c-2);
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
  column-gap: 5px;
  font-weight: 700;
  color: var(--c-1);
  & a{
    color: var(--c-2);
    &:hover{
      text-decoration: underline;
    }
  }

}

.white_result-of-search:hover{
  text-transform: uppercase;
}

.active{
  display: flex !important;
}

.white_result-of-search{
  padding: 20px 0;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  border: 2px solid var(--c-1);
  border-radius: 10px;
  margin-bottom: 15px;
  &_link{
    font-size: 22px;
    color: var(--c-2);
    margin-left: 20px;
  }
  &_box{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.inform_search{
  width: 500px;
}

.white{
  display: flex;
  flex-direction: column;
  p{
    font-size: 28px;
    text-align: center;
    color: #fff;
  }
  &_card_artist, &_card_tracklink{
    overflow: hidden;
    max-height: 20px;
  }
  &_card{
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
    font-size: 18px;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 8px 13px;
    background: var(--c-4);
    margin-bottom: 15px;
    &_artist{
      margin-right: 5px;
    }
    &:hover{
      border: 2px solid var(--c-5); 
      .white_card_artist::after, .white_card_artist, .white_card_tracklink, .dur{
        color: var(--c-1);
      }
    }   
  
  }
  &_card.pausing a.white_card_link::before{
    content: url('../images/card-play.svg');
  }
  &_card.plays a.white_card_link::before{
    content: url('../images/card-pause.svg');
  }
  &_card_like{
    height: 30px;
  }
  &_card .start {
    cursor: pointer;
} 
  &_card-subtext{
    position: absolute;
    right: 110px;
  }

  &_card_share{
    right: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 2px);
    width: 26px;
  }
  &_card_artist, &_card_tracklink{
    color: var(--c-2);
    width: 100%;
    font-weight: 700;
  }
  &_card_download{
    cursor: pointer;
    align-items: center;
    justify-content: center;
    fill: var(--c-2);
    position: absolute;
    right: 20px;
    svg{
      width: 50px;
      height: 50px;
    }
  }
  &_card_link{
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    margin-right: 20px;
    color: var(--white-c);

    &::before {
      content: url('../images/card-play.svg');
      width: 54px;
      height: 54px;
    }
  }
}

.white_player-logo{
  width: 55px;
  height: 55px;
}

.white_player-title{
  column-gap: 15px;
  display: flex;
  align-items: center;
}

.white_card_artist::after{
  content: ' - ';
  font-weight: 700;
  font-size: 23px;
  margin-left: 5px;
  margin-right: 5px;
}

.white_music{
  &__title{
    display: flex;
    align-items: center;
  }
  &__title-heading{
    text-align: center;
    width: 100%;
    color: var(--c-2);
    font-size: 26px;
    margin: 15px 0;
  }
  &__title-logo{
    width: 32px;
    height: 36px;
    fill: var(--c-1);
    margin-right: 30px;
  }
}

.submit {
  cursor: pointer;
  cursor: pointer;
  transition: all .2s ease;
  border: none;
  background: 0 0;
  padding: 10px;
  svg {
    width: 35px;
    height: 35px;
    stroke: var(--c-2);
    fill: var(--c-2);
  }
}

.dur {
  font-weight: 700;
  color: var(--c-2);
}

.title {
 overflow: hidden;
 line-height: 35px;
}

.white_logo-link{
  font-size: 35px;
  font-weight: 700;
  color: var(--c-1);
}

.white_footer{
  margin-top: 10px;
  &_wrapper {
    font-size: 20px;
    position: relative;
    color: var(--c-1);
  }
  &_copy{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.white_player-link{
  font-size: 30px;
  color: #fff;
}

.white_story_track-title{
  font-weight: 700;
  color: var(--c-2);
  transition: .5s ease;
  display: flex;
  margin-bottom: 20px;
  padding: 7px;
  border-radius: 5px;
  background-color: var(--c-3);
  width: 100%;
  text-align: center;
  justify-content: center;
  &:hover{
    background-color: var(--c-5);
    color: #fff;
  }
}

.white_story{
  &_heading{
    text-align: center;
    width: 100%;
    color: var(--c-2);
    font-size: 26px;
    margin: 15px 0;
  }
  &_track-link{
    width: 100%;
    font-size: 20px;
    display: flex;
  }
  &__track-img{
    height: 50%;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;  
    color: var(--white-c);
    i{
      font-size: 30px;
    }
  }
  &__track-title{
    font-size: 20px;
    font-weight: 700;
  }
}

.f-1{
  margin-bottom: 150px;
}

.white_description{
  width: 450px;
  font-size: 22px;
  &_content{
    display: flex;
    justify-content: center;
    color: var(--c-2);
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    justify-content: space-between;
    row-gap: 15px;
    align-items: center;
  }
  &_icon{
    font-size: 25px;
    margin-right: 10px;
  }
  &_title{
    display: flex;
    color: var(--c-2);
    display: flex;
    flex-wrap: wrap;
    font-size: 22px;
    } 
  }

.white_boxline{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.white_copyright_box{
  &-logo{
    padding: 0 10px;
  }
  &-mail{
    font-weight: 700;
    column-gap: 5px;
    justify-content: center;
    color: var(--c-1);
    display: flex;
    font-size: 25px;
    align-items: center;
    a{
      color: var(--c-1);
      font-size: 25px;
      font-weight: 700;
    }
  }
  &-link{
    color: var(--c-14);
  }
}

.white_social-box--btnBox {
  flex-wrap: wrap;
  position: relative;
  padding: 5px;
}

.about{
  &__inner{
    margin-bottom: 20px;
    padding: 5px;
    color: var(--c-2);
    
  }
}

.boxline{
  margin: 10px 0;
  &__top p{
    line-height: 1.5;
    font-size: 20px;
    color: #fff;
  }
  &__bottom{
    font-size: 20px;
    font-weight: 700;
    color: var(--white-c);
    text-align: center;
  }
}

 .white_sotial-network-vk {
  &_btn{
    box-sizing: border-box;
    border-radius: 4px;
    height: 60px;
    background-color: var(--c-1);
    width: 100%;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    color: var(--c-2);
    text-transform: uppercase;
    &::placeholder{
      color: var(--c-2);
    }
  }
  &_link{
    margin-top: 15px;
    border-radius: 4px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
    border: 3px solid var(--c-2);
    color: var(--c-2);
    position: relative;
    overflow: hidden;
    z-index: 1;
    -webkit-transition: c 150ms ease-in-out;
    height: 60px;
    display: flex;
    width: 100%;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 0;
      height: 100%;
      background: var(--c-1);

      z-index: -1;
      -webkit-transition: width 1s ease-in-out;
      transition: width 1s ease-in-out;
    }
    &:hover::after{
      width: 110%;
    }
  }
  &_input{
    padding: 0 15px;
    margin-bottom: 20px;
      display: flex;
      flex-direction: column;
  }
  &_content{
    line-height: 2;
    font-size: 20px;
    color: var(--c-2);
    font-weight: 700;
  }
}

.active__selection::marker,.active__genre::marker{
  color: var(--c-1);
}
.active__selection,.active__genre{
  font-weight: 700;
  color: var(--c-1);
  font-size: 18px;
  margin-bottom: 5px;
} 

#genre{
  padding: 25px 0 0;
  list-style-type: none;
  position: relative;
}

.white_story_track{
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  margin-top: 30px;
}

.white_genre{
  &-list{
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
  }
  &-item{
    transition: .5s ease;
    display: flex;
    margin-bottom: 20px;
    padding: 7px;
    border-radius: 5px;
    background-color: var(--c-3);
    width: 100%;
    text-align: center;
    justify-content: center;
    &:hover{
      transition: .5s ease;
      background-color: var(--c-5);
      color: #fff;
      .white_genre-item_link{
        color: var(--c-3);
      }
    }
    &_link{
      font-size: 20px;
      color: var(--c-2);
    }
  }
  &-item_logo{
    margin-right: 15px;
    font-size: 25px;
    color: var(--c-14);
  }
}
.active__selection{
  list-style: none;
}

#trackAjax {
  z-index: 1;
  padding: 0;
}

#inform_search__form {
  background: var(--c-4);
  display: flex;
  align-items: center;
  height: auto;
  width: 100%;
  border-radius: 4px;
}



.pleer {
  z-index: 99999;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: flex-start;
  margin: 0 auto;
  background-color: var(--c-3);
}



.white_volume {
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: 14px;
  margin-top: 0;
  border-radius: 4px;
  z-index: 200;
  position: relative;
  background: var(--c-2);
  .white_volume__line {
    position: absolute;
    top: 0;
    cursor: pointer;
    height: 14px;
    float: left;
    -webkit-transition: width 1s ease;
    transition: width 1s ease;
    background: var(--c-1);
  }
}



.time-box {
  width: 65px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-6);
}

.share-box {
  width: 38px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-6);
}

.download-box {
  width: 42px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-6);
}

a.play {
  color: var(--white-c);
  //border: none;

  &:hover {
    text-decoration: none;
  }
}

a.pause {
  color: var(--white-c);
}


.play,.pause{
  display: flex;
  align-items: center;
  justify-content: center;
}
.play {
  &::before {
    content: url("../images/card-play.svg");
    width: 70px;
    height: 70px;
    margin: 0;
  }
}
.pause {
  &::before {
    content: url('../images/card-pause.svg');
    width: 70px;
    height: 70px;
    margin: 0;
  }
}

.white_track-central_left-btn,
.white_track-central_right-btn{
  svg{
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  }
}

.white_track-central_left-btn svg{
  transform: rotate(180deg);
}

.uil{
  cursor: pointer;
}

.white_track-central{
  align-items: center;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  margin-right: 15px;
  column-gap: 10px;
}

.progressBarContainer-playBar{
  position: relative;
  display: inline-block;
  height: 11px;
  background: var(--c-2);
  color: var(--c-2);
}

.progressBarContainer--downloadBar{
  display: block;
  position: absolute;
  height: 70px;
  width: 0;
}

.download-bar{
  display: block;
  height: 70px;
  width: 100px;
  z-index: 201;
  border-radius: 20px;
}

.progress-bar__container-playbar {
  position: absolute;
  left: 0;
  display: inline-block;
  height: 15px;
  color: var(--c-2);
  align-items: center;
  transition: opacity .3s ease-in-out;
  background: var(--c-1);
  position: absolute;
  border-radius: 0 5px 5px 0;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 0;
    width: 6px;
    height: calc(100% + 10px);
    background: var(--white-c);
    border: 2px solid #061161;
    display: none;
  }
}



.track {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 5px;
}

.track__track-timebox {
  position: absolute;
  width: 100%;
  height: 15px;
  background: var(--c-2);
}

.progress-bar__container {
  cursor: pointer;
  transition: height linear 0.3s;
  height: 25px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.white_zero-time {
  font-size: 14px;
}

.white_timeBox {
  margin-right: 30px;
  font-size: 14px;
}

.white_player-title_heading {
  z-index: 2;
  text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    font-size: 20px;
    margin-left: 0;
    color: var(--c-2);
}

.white_mute-box {
  margin-left: 20px;
  width: 210px;
  justify-content: flex-end;
  display: flex;
  .SocialBox{
    min-height: 36px;
    justify-content: flex-start;
    .SocialBox--btnBox{
      border-color: var(--white-c);
    }
  }

  &__muteUnmute{
    column-gap: 10px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.loaded {
  display: none;
}


.white_time{
  &_space{
    font-weight: 700;
    margin: 0 5px;
  }
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: var(--c-4);
    position: absolute;
    font-weight: 700;
    left: 20px;
    z-index: 2;
    top: 0;
    bottom: 0;
    align-items: center;
}

.white_track__leftWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .player__Collapse{
    display: none;
  }
}

.white_playerTime__box{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.white_xplayer-title{
  width: 100%;
  display: flex;
  align-items: center;
}

.white_player-title_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  svg {
    width: 40px;
    height: 30px;
    fill: var(--c-14);
  }
}

a.uir {
  &:hover {
    text-decoration: none;
  }
}

.uir {
  float: left;
  cursor: pointer;
  width: 60px;
  height: 60px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
}

.white_mute,
.white_unmute{
  svg{
    position: relative;
    display: inline-block;
    width: 45px;
    height: 45px;
  }
}
.white_mute{
  fill: var(--c-1);
}
.white_unmute{
  fill: none;
}



#GeneralBox,
#toTop{
  display: none;
}

.relative {
  position: relative;
}

.dropdown-menu {
  min-width: 160px;
  white-space: nowrap;

  .nav-link {
    &:focus {
      background-color: var(--c-3);
    }

    &:hover {
      background-color: var(--c-3);
    }
  }
}

.dropdown.show {
  .nav-link.dropdown-toggle {
    background-color: var(--c-3);
  }
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}




/*SHAREBOX*/
span{
  &.white_shareblock{
    border-radius: 15px;
    z-index: 10000;
    width: 500px;
    display: block;
    background-color: var(--c-11);
    position: fixed;
    top: 50%;
    left: 20%;
    margin: -100px 0 0;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    border: 1px solid var(--c-2);
    b {
      border: 4px solid var(--c-1);
      position: absolute;
      top: -20px;
      right: -20px;
      display: block;
      width: 40px;
      height: 40px;
      text-align: center;
      margin-bottom: -24px;
      background-color: #fff;
      cursor: pointer;
      border-radius: 30px;
      line-height: 40px;
      font-size: 25px;
    }
    h1 {
      padding: 10px 15px;
      margin: 0;
      background: var(--c-11);
      font-family: sans-serif;
      font-size: 25px;
      font-weight: 400;
      display: block;
      color: var(--c-1);
      border-bottom: 4px solid var(--white-c);
    }
    span {
      width: 400px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 25px 0;    
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
    }
    a {
      color: var(--c-1);
      font-size: 25px;
      background: url(../images/share.png) 10px 0 no-repeat;
      margin: 10px 10px 10px 0;
      padding: 0 10px 0 25px;
      &.vk {
        background-position: 0 4px;
      }
      &.tw {
        background-position: 0 -56px;
      }
      &.ok {
        background-position: 0 -86px;
      }
      &.mm {
        background-position: 0 -116px;
      }
      &.gp {
        background-position: 0 -146px;
      }
    }
  }
}

.white_active__selection{
  &-content{
  display: flex;
  &:hover{
    & a{
      color: var(--c-1) !important;
    }
    .white_active__selection-like{
      fill: var(--c-1);
    }
  }
  a{
    color: var(--c-14);
  }
}
  &-like{
    fill: var(--c-14);
    width: 40px;
    height: 40px;
    margin-left: 20px;
  }
}

.white_blueSidebar{
  flex-direction: column;
  width: 40%;
  row-gap: 20px;
  display: flex;
  &_list{
    flex-direction: column;
    width: 300px;
    display: flex;
    margin-left: 40px;
  }
  & ul{
    font-weight: 700;
    margin-bottom: 0;
  }
  .white_sidebar-header {
    border-radius: 15px;
    padding: 20px 0 10px;
    background: var(--c-10);
    &_logo{
      a{
        color: var(--white-c);
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  ul.components {
    padding: 20px 0;
  }
  ul {
    p {
      color: var(--white-c);
      padding: 10px;
      font-size: 18px;
    }
  
  }
  a[aria-expanded="true"] {
    position: relative;
    font-size: 35px;
  }
  a[data-toggle="collapse"] {
    position: relative;
  }
  a.article {
    color: var(--white-c) !important;
    &:hover {
      background: var(--c-10) !important;
      color: var(--white-c) !important;
    }
  }

}


